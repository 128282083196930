import { useEffect, useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '@app/materialUi/theme';

const isBridgeTricorn = import.meta.env.VITE_IS_BRIDGE_TRICORN?.trim() !== 'false';
function App() {
    const [importedComponent, setImportedComponent] = useState<JSX.Element | null>(null);

    useEffect(() => {
        const importComponent = async () => {
            const module = await import(
                isBridgeTricorn
                    ? './TricornApp'
                    : './RunesKeysApp'
            );
            const BridgeApp = module.default;
            setImportedComponent(<BridgeApp />);
        };

        importComponent();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {importedComponent}
        </ThemeProvider>
    );
};

export default App;
