/* eslint-disable-file @typescript-eslint/no-empty-interface */

import { PaletteOptions } from '@mui/material';

const isBridgeTricorn = import.meta.env.VITE_IS_BRIDGE_TRICORN?.trim() !== 'false';
declare module '@mui/material/styles' {
    interface ICustomPalette {
        black: string;
        white: string;
        green: string;
        borderGrey: string;
        innactiveBorder: string;
        mainGradient: string;
        innactiveGradient: string;
        greenHover: string;
        gradientGreen: string;
        gradientGreenRadial: string;
        gradientGrayRadial: string;
        mainGrey: string;
        lightGrey: string;
        darkGrey: string;
        darkerGrey: string;
        successGrey: string;
        navGrey: string;
        gradientGrey: string;
        gradientGreyHover: string;
        gradientGreyBackground: string;
        walletConnectPopupBackground: string;
        walletConnectPopupBorder: string;
        walletConnectItemBackground: string;
        walletConnectPreviewBackground: string;
        walletConnectLabelBorder: string;
        red: string;
        settingsMenuBackground: string;
        settingsMenuBorder: string;
        dividerBackground: string;
        slideBackground: string;
        baseLinkColor: string;
        chainsBorderColor: string;
        runesAccentGradient: string;
        runesDisabledAccentGradient: string;
        runesSettingsMenuBackground: string;
        runesDividerBackground: string;
        runesDisabledTextColor: string;
        runesBackground: string;
        runesBackgroundGradient: string;
        runesButtonColorSuccess: string;
    }

    // allow configuration using `createTheme`
    // interface ThemeOptions extends ICustomPalette {}

    interface Theme extends ICustomPalette { }

    interface Palette extends ICustomPalette { }

    interface PaletteOptions extends ICustomPalette { }
}

export const palette: PaletteOptions = {
    black: '#000000',
    white: '#FFFFFF',
    green: isBridgeTricorn ? '#17C015' : '#009c00',
    greenHover: '#198618',
    borderGrey: 'rgb(134, 139, 133)',
    innactiveBorder: '#383c37',
    mainGradient: 'linear-gradient(90deg, rgba(227, 233, 234, 0.32) 0%, rgba(255, 255, 255, 0.09) 100%)',
    innactiveGradient: 'linear-gradient(90deg, rgba(227, 233, 234, 0.1) 0%, rgba(255, 255, 255, 0.03) 100%)',
    gradientGreen: 'linear-gradient(268.72deg, #208E27 -1.2%, #18BE15 44.4%, #21872A 101.8%)',
    gradientGreenRadial: 'radial-gradient(50% 50% at 50% 50%, #3F9122 0%, #000000 100%)',
    gradientGrayRadial: 'radial-gradient(50% 50% at 50% 50%, #E0D5BD7A 0%, #70909D7A 100%)',
    mainGrey: '#9CA2AD',
    lightGrey: '#CACACA',
    darkGrey: '#383838',
    darkerGrey: '#1B1B1B',
    successGrey: 'rgba(37, 37, 37, 0.7)',
    navGrey: '#515151',
    gradientGrey: 'linear-gradient(90.19deg, rgba(175, 175, 175, 0.44) 1.13%, rgba(104, 104, 104, 0.58) 99.85%)',
    gradientGreyHover: 'linear-gradient(90.19deg, rgba(138, 138, 138, 0.44) 1.13%, rgba(104, 104, 104, 0.58) 99.85%)',
    gradientGreyBackground: '#5a5a5a57',
    walletConnectPopupBackground: 'rgba(35,50,41,0.95)',
    walletConnectPopupBorder: 'rgb(134, 139, 133)',
    walletConnectItemBackground: 'linear-gradient(90.19deg, rgba(175, 175, 175, 0.44) 1.13%, rgba(104, 104, 104, 0.58) 99.85%)',
    walletConnectPreviewBackground: 'rgb(22, 22, 22)',
    walletConnectLabelBorder: 'rgba(108, 108, 108, 0.23)',
    red: isBridgeTricorn ? '#FF4646' : '#a10505',
    settingsMenuBackground: '#262c26',
    settingsMenuBorder: 'rgb(98, 98, 98)',
    dividerBackground: 'rgba(104, 104, 104, 0.88)',
    slideBackground: 'rgb(46, 51, 45)',
    baseLinkColor: 'rgb(156, 162, 173)',
    chainsBorderColor: 'rgba(175, 175, 175, 0.44)',
    runesAccentGradient: 'linear-gradient(90deg, #E0D5BD -10.76%, #70909D 109.06%)',
    runesDisabledAccentGradient: 'linear-gradient(90deg, rgba(224, 213, 189, 0.44) -10.76%, rgba(112, 144, 157, 0.44) 109.06%)',
    runesSettingsMenuBackground: 'linear-gradient(90deg, rgb(25, 24, 24) 0%, black 100%)',
    runesDividerBackground: 'rgba(255, 255, 255, 0.06)',
    runesDisabledTextColor: 'rgb(179, 179, 179)',
    runesBackground: '#000000',
    runesBackgroundGradient: 'linear-gradient(90deg, rgba(224, 213, 189, 0.48) -10.76%, rgba(112, 144, 157, 0.48) 55.08%)',
    runesButtonColorSuccess: 'rgb(140 173 140)',
};
