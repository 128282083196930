import { NetworksClient } from '@/api/networks';
import { Network, Token, TokenResponse, TokenSearch } from '@/networks';

/**
 * Exposes all networks domain entities related logic.
 */
export class NetworksService {
    protected readonly networks: NetworksClient;

    public constructor(networks: NetworksClient) {
        this.networks = networks;
    };

    /** Requests connected networks list. */
    public async connected(activeTokenAmountId?: number): Promise<Network[]> {
        return await this.networks.connected(activeTokenAmountId);
    };

    /** Requests supported tokens list. */
    public async supportedTokens(networkId: number, filter?: TokenSearch): Promise<TokenResponse> {
        return await this.networks.supportedTokens(networkId, filter);
    };

    /** Sends request to update user runes list. */
    public async userTokens(networkId: number, userAddress: string): Promise<void> {
        return await this.networks.userTokens(networkId, userAddress);
    };

    /** Requests supported token by id. */
    public async supportedTokenById(networkId: number, tokenId: number): Promise<Token> {
        return await this.networks.supportedTokenById(networkId, tokenId);
    };

    /** Requests supported recipient token by slected token Id to send. */
    public async supportedRecipientToken(networkId: number, activeSenderTokenId: number): Promise<Token> {
        return await this.networks.supportedRecipientToken(networkId, activeSenderTokenId);
    };

    /** Requests token balance. */
    public async balance(networkId: number, tokenId: number, address: string): Promise<string> {
        return await this.networks.balance(networkId, tokenId, address);
    };
};
