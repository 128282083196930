import { CLEAR_NETWORK_DATA, SET_TOKEN_BALANCE } from './../actions/networks';
import { Network, Token, TokenResponse } from '@/networks';
import {
    SET_ACTIVE_SUPPORTED_RECIPIENT_TOKEN,
    SET_ACTIVE_SUPPORTED_TOKEN,
    SET_ACTIVE_SUPPORTED_TOKEN_WITHOUT_RECIPIENT,
    SET_CONNECTED_NETWORKS,
    SET_SUPPORTED_TOKENS,
} from '@/app/store/actions/networks';

/** Exposes networks state. Uses as default state for reducer. */
class NetworksState {
    constructor(
        public networks: Network[] = [],
        public supportedTokens: TokenResponse = new TokenResponse(),
        public activeSupportedToken: Token = new Token(),
        public activeSupportedRecipientToken: Token = new Token(),
        public balance: string = '',
        public activeRecipientNetworks: Network[] = [],
    ) { }
}

/** NetworksReducerAction uses as action payload for reducer. */
class NetworksReducerAction {
    constructor(public type: string = '', public payload: any = '') { }
}
/** NetworksReducer */
export const networksReducer = (
    networksState: NetworksState = new NetworksState(),
    action: NetworksReducerAction = new NetworksReducerAction(),
) => {
    switch (action.type) {
        case SET_CONNECTED_NETWORKS:
            networksState.networks = action.payload;
            break;
        case SET_SUPPORTED_TOKENS:
            networksState.supportedTokens = action.payload;
            break;
        case SET_ACTIVE_SUPPORTED_RECIPIENT_TOKEN:
            networksState.activeSupportedRecipientToken = action.payload;
            break;
        case SET_ACTIVE_SUPPORTED_TOKEN:
            networksState = {
                ...networksState,
                activeSupportedToken: action.payload.token,
                activeRecipientNetworks: action.payload.recipientNetworks,
            };
            break;
        case SET_ACTIVE_SUPPORTED_TOKEN_WITHOUT_RECIPIENT:
            networksState.activeSupportedToken = action.payload;
            break;
        case SET_TOKEN_BALANCE:
            networksState.balance = action.payload;
            break;
        case CLEAR_NETWORK_DATA:
            networksState = {
                ...networksState,
                balance: '',
                activeSupportedToken: new Token(),
            };
            break;
        default:
            return networksState;
    }

    return { ...networksState };
};
