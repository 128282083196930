import { toast } from 'react-toastify';

import notificationsMessages from '@/app/configs/notificationsMessages.json';

import {
    CopyIconNotification,
    ErrorNotificationIcon,
    SuccessNotificationIcon,
} from '@app/static/images/svg';
import { Loader } from '@app/static/images';
import { WalletName } from '@app/store/reducers/wallets';

/** Notifications themes. Contains colored, light and dark themes. */
export enum Themes {
    colored = 'colored',
    dark = 'dark',
    light = 'light',
}

/** Notifications types. I.e, error, info, success, warning. */
export enum DesignTypes {
    error = 'error',
    info = 'info',
    success = 'success',
    warning = 'warning',
}

/** Notifications position on page. */
export enum PositionsOnPage {
    BOTTOM_CENTER = 'bottom-center',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    TOP_CENTER = 'top-center',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
}

const NotificationIcons: Record<DesignTypes, React.FC | undefined> = {
    [DesignTypes.success]: SuccessNotificationIcon,
    [DesignTypes.error]: ErrorNotificationIcon,
    [DesignTypes.info]: undefined,
    [DesignTypes.warning]: undefined,
};

/** Defines notifications plugin with message, toast type and theme. */
export class NotificationsPlugin {
    /** Notifies user. As default type uses error type, and default theme is colored. */
    static notify(message: any, customIcon?: React.FC | null, type: DesignTypes = DesignTypes.error) {
        const uniqueToastId = `error-toast-${message}`;

        if (!toast.isActive(uniqueToastId)) {
            toast[type](message, {
                toastId: uniqueToastId,
                position: toast.POSITION.BOTTOM_RIGHT,
                icon: customIcon || NotificationIcons[type],
                theme: Themes.dark,
                closeButton: false,
                className: import.meta.env.VITE_IS_BRIDGE_TRICORN?.trim() !== 'false' ? '' : 'runes_keys',
            });
        }
    }

    /** Notifies that casper wallet isn't connected. */
    static casperIsNotConnected() {
        this.notify(notificationsMessages.casperIsNotConnected);
    }

    /** Notifies that casper transaction has been canceled. */
    static casperCancelTransaction() {
        this.notify(notificationsMessages.casperCancelTransaction);
    }

    /** Notifies that concordium wallet isn't connected. */
    static concordiumIsNotConnected() {
        this.notify(notificationsMessages.concordiumIsNotConnected);
    }

    /** Notifies that stellar wallet isn't connected. */
    static stellarIsNotConnected() {
        this.notify(notificationsMessages.stellarIsNotConnected);
    }

    /** Notifies that concordium wallet extension is already opened. */
    static concordiumIsAlreadyOpened() {
        this.notify(notificationsMessages.concordiumIsAlreadyOpen);
    }

    /** Notifies that sender/recipient network should be changed. */
    static changeNetwork() {
        this.notify(notificationsMessages.changeNetwork);
    }

    /** Notifies that message was copied. */
    static copied() {
        this.notify(notificationsMessages.copied, CopyIconNotification, DesignTypes.info);
    }

    /** Notifies that could not cancel transfer. */
    static couldNotCancelTransfer() {
        this.notify(notificationsMessages.couldNotCancelTransfer);
    }

    /** Notifies that could not cancel not "Waiting" transfer. */
    static couldNotCancelNotWaitingTransfer() {
        this.notify(notificationsMessages.couldNotCancelNotWaitingTransfer);
    }

    /** Notifies that could not authenticate via MetaMask. */
    static couldNotAuthenticateViaMetaMask() {
        this.notify(notificationsMessages.couldNotAuthenticateViaMetaMask);
    }

    /** Notifies that could not connect via MetaMask. */
    static couldNotConnectViaMetaMask() {
        this.notify(notificationsMessages.couldNotConnectViaMetaMask);
    }

    /** Notifies that could not get connected networks. */
    static couldNotGetConnectedNetworks() {
        this.notify(notificationsMessages.couldNotGetConnectedNetworks);
    }

    /** Notifies that could not get token balance. */
    static couldNotGetBalance() {
        this.notify(notificationsMessages.couldNotGetBalance);
    }

    /** Notifies that not enough tokens on balance. */
    static notEnoughBalance() {
        this.notify(notificationsMessages.notEnoughBalance);
    }

    /** Notifies that impossible to make transfer. */
    static impossibleToMakeTransfer() {
        this.notify(notificationsMessages.impossibleToMakeTransfer);
    }

    /** Notifies that impossible to make transfer. */
    static previousTransactionIsNotCompleted() {
        this.notify(notificationsMessages.previousTransactionIsNotCompleted);
    }

    /** Notifies that could not get supported tokens. */
    static couldNotGetSupportedTokens() {
        this.notify(notificationsMessages.couldNotGetSupportedTokens);
    }

    /** Notifies that could not get transfers history. */
    static couldNotGetTransfersHistory() {
        this.notify(notificationsMessages.couldNotGetTransfersHistory);
    }

    /** Notifies that could not get casper wallet address. */
    static couldNotGetCasperWalletAddress() {
        this.notify(notificationsMessages.couldNotGetCasperWalletAddress);
    }

    /** Notifies that could not get metamask wallet address. */
    static couldNotGetMetamaskWalletAddress() {
        this.notify(notificationsMessages.couldNotGetMetamaskWalletAddress);
    }

    /** Notifies that could not estimate transfer. */
    static couldNotEstimateTransfer() {
        this.notify(notificationsMessages.couldNotEstimateTransfer);
    }

    /** Notifies that could not send transaction via Casper wallet. */
    static couldNotSendTransactionViaCasperWallet() {
        this.notify(notificationsMessages.couldNotSendTransactionViaCasperWallet);
    }

    /** Notifies that could not send transaction via Freighter wallet. */
    static couldNotSendTransactionViaFreighterWallet() {
        this.notify(notificationsMessages.couldNotSendTransactionViaFreighterWallet);
    }

    /** Notifies that could not send transaction via MetaMask wallet. */
    static couldNotSendTransactionViaMetaMaskWallet() {
        this.notify(notificationsMessages.couldNotSendTransactionViaMetaMaskWallet);
    }

    /** Notifies that could not submit transaction via Freighter wallet. */
    static couldNotSubmitTransactionViaFreighterWallet() {
        this.notify(notificationsMessages.couldNotSubmitTransactionViaFreighterWallet);
    }

    /** Notifies that could not create transaction via Freighter wallet. */
    static couldNotCreateTransactionForFreighterWallet() {
        this.notify(notificationsMessages.couldNotCreateTransactionForFreighterWallet);
    }

    /** Notifies that swap fields must be not empty. */
    static emptySwapFields() {
        this.notify(notificationsMessages.emptySwapFields);
    }

    /** Notifies that metamask wallet isn't connected. */
    static metamaskIsNotConnected() {
        this.notify(notificationsMessages.metamaskIsNotConnected);
    }

    /** Notifies that could not connect Casper. */
    static connectCasperError() {
        this.notify(notificationsMessages.connectCasperError);
    }

    /** Notifies that action was success. */
    static success() {
        this.notify(notificationsMessages.success, null, DesignTypes.success);
    }

    /** Notifies that transferred token isn`t selected. */
    static tokenIsNotSelected() {
        this.notify(notificationsMessages.tokenIsNotSelected);
    }

    /** Notifies that transaction via MetaMask was canceled. */
    static transactionViaMetaMaskWasCanceled() {
        this.notify(notificationsMessages.transactionViaMetaMaskWasCanceled);
    }

    /** Notifies that transaction via MetaMask has default error. */
    static metamaskError() {
        this.notify(notificationsMessages.metamaskError);
    }

    /** Notifies that MetaMask is locked. */
    static metamaskIsLocked() {
        this.notify(notificationsMessages.metamaskIsLocked);
    }

    /** Notifies that transaction via Concordium has default error. */
    static concordiumError() {
        this.notify(notificationsMessages.concordiumError);
    }

    /** Notifies that transaction via Stellar has default error. */
    static stellarError() {
        this.notify(notificationsMessages.stellarError);
    }

    /** Notifies that MetaMask is locked. */
    static freighterIsLocked() {
        this.notify(notificationsMessages.freighterIsLocked);
    }

    /** Notifies that failed to get transaction status */
    static failedToGetTransactionStatus() {
        this.notify(notificationsMessages.failedToGetTransactionStatus);
    }

    /** Notifies that error during transaction processing */
    static errorDuringTransactionProcessing() {
        this.notify(notificationsMessages.errorDuringTransactionProcessing);
    }

    /** Notifies that user rejected concordium signing. */
    static rejectSigningError() {
        this.notify(notificationsMessages.signingRejeced);
    }

    /** Notifies that browser do not support this wallet extension. */
    static browserNotSupportExtension() {
        this.notify(notificationsMessages.browserNotSupportExtension);
    }

    /** Notifies that user need open metamask browser */
    static openMetamaskBrowser() {
        this.notify(notificationsMessages.openMetamaskBrowser);
    }

    /** Notifies that user need open xverse browser */
    static openXverseBrowser() {
        this.notify(notificationsMessages.openXverseBrowser);
    }

    /** Notifies that user has insufficient balance. */
    static insufficientBalance() {
        this.notify(notificationsMessages.insufficientBalance);
    }

    /** Notifies that action was success transaction. */
    static transactionSuccess() {
        this.notify(notificationsMessages.transactionSuccess, null, DesignTypes.success);
    }

    /** Notifies that transfer successfully canceled. */
    static transferSuccessfullyCanceled() {
        this.notify(notificationsMessages.transferSuccessfullyCanceled, null, DesignTypes.success);
    }

    /** Notifies that need to unlock Casper Wallet. */
    static unlockCasperWallet() {
        this.notify(notificationsMessages.unlockCasperWallet);
    }

    /** Notifies that wallet successfully connected. */
    static walletSuccessfullyConnected() {
        this.notify(notificationsMessages.walletSuccessfullyConnected, null, DesignTypes.success);
    }

    /** Notifies that wallet address is not valid. */
    static walletAddressNotValid() {
        this.notify(notificationsMessages.walletAddressNotValid);
    }

    /** Notifies that casper account hash isn't valid */
    static casperAccountHashIsNotValid() {
        this.notify(notificationsMessages.casperAccountHashIsNotValid);
    }

    // TODO: For MVP (in feature will be deleted)
    /** Notifies that token amount isn't integer */
    static tokenAmountIsNotInteger() {
        this.notify(notificationsMessages.tokenAmountIsNotInteger);
    }

    /** Notifies that transaction is waiting for approval */
    static waitingForApproval() {
        this.notify(notificationsMessages.waitingForApproval, Loader, DesignTypes.success);
    }

    /** Notifies that Xverse wallet isn't connected. */
    static xverseIsNotConnected() {
        this.notify(notificationsMessages.xverseIsNotConnected);
    }

    /** Notifies that xverse connect has been canceled */
    static xverseCancelConnect() {
        this.notify(notificationsMessages.xverseCancelConnect);
    }

    /** Notifies that transaction via Xverse has default error. */
    static xverseError() {
        this.notify(notificationsMessages.xverseError);
    }

    /** Notifies that Xverse is locked. */
    static xverseIsLocked() {
        this.notify(notificationsMessages.xverseIsLocked);
    }

    /** Notifies that Concordium is locked. */
    static concordiumIsLocked() {
        this.notify(notificationsMessages.concordiumIsLocked);
    }

    /** Notifies that Xverse sending transaction cancelled. */
    static xverseTransactionWasCanceled() {
        this.notify(notificationsMessages.transactionViaXverseWasCanceled);
    }

    /** Notifies that wallet was disconnected. */
    static walletDisconnected(wallet: WalletName) {
        this.notify(`${wallet} ${notificationsMessages.walletDisconnected}`);
    }

    /** Notifies that wallet was connected. */
    static walletConnected(wallet: WalletName) {
        this.notify(`${wallet} ${notificationsMessages.walletConnected}`, null, DesignTypes.success);
    }

    /** Notifies that insufficient native funds to make the transfer */
    static insufficientNativeFunds() {
        this.notify(notificationsMessages.insufficientNativeFunds)
    }
}
