import { META_TAGS_CONFIG, parseMetaTag } from '@app/internal/parseMetaTag';
import { APIClient } from '@/api';
import { Network, Token, TokenResponse, TokenSearch } from '@/networks';

/**
 * NetworksClient is a http implementation of networks API.
 * Exposes all networks-related functionality.
 */
export class NetworksClient extends APIClient {
    protected readonly ROOT_PATH: string = `${parseMetaTag(META_TAGS_CONFIG.GATEWAY_ADDRESS)}/api/v0`;
    /** Requests connected networks list.
     * @param {number} activeTokenAmountId - Active token amount ID to get recipient networks
     * @returns {Network[]} - Connected networks list
     */
    public async connected(activeTokenAmountId?: number): Promise<Network[]> {
        const response = await this.http.get(`${this.ROOT_PATH}/networks${activeTokenAmountId ? `?token-id=${activeTokenAmountId}` : ''}`);
        if (!response.ok) {
            await this.handleError(response);
        }

        const networks = (await response.json()) || [];

        return networks.map(
            (network: Network) =>
                new Network(
                    network.id,
                    network.name,
                    network.type,
                    network.bridgeContract,
                    network.gasLimit,
                    network.active,
                    network.iconLink,
                    network.explorerBaseUrl,
                )
        );
    };
    /** Requests supported tokens list.
     * @param {number} networkId - Active network ID
     * @param {string} userAddress - Active user address
     * @returns {void}
     */
    public async userTokens(networkId: number, userAddress: string): Promise<void> {
        const response = await this.http.get(
            `${this.ROOT_PATH}/networks/${networkId}/fetch-user-tokens/${userAddress}`
        );
        if (!response.ok) {
            await this.handleError(response);
        }
    };

    /** Requests supported tokens list.
     * @param {number} networkId - Active network ID to send transaction
     * @param {TokenSearch | undefined} filter - Object with user's search input result, limit and page
     * @returns {TokenResponse[]} - Supported tokens list for active network together with pagination info
     */
    public async supportedTokens(networkId: number, filter?: TokenSearch): Promise<TokenResponse> {
        const response = await this.http.get(
            `${this.ROOT_PATH}/networks/${networkId}/supported-tokens${
                filter ? `?search=${filter.search}&limit=${filter.limit}&page=${filter.page}`
                    : ''
            }`
        );
        if (!response.ok) {
            await this.handleError(response);
        }

        const tokenResponse: TokenResponse = (await response.json()) || [];
        const tokens = tokenResponse.Tokens.map(
            (token: Token) =>
                new Token(
                    token.decimals,
                    token.id,
                    token.shortName,
                    token.longName,
                    token.smartContractAddress,
                    token.iconLink,
                )
        );

        return new TokenResponse(
            tokens,
            tokenResponse.TotalCount,
            tokenResponse.Limit,
            tokenResponse.Offset,
            tokenResponse.PageCount,
            tokenResponse.CurrentPage
        );
    };

    /** Requests supported tokens list.
     * @param {number} networkId - Active network ID to send transaction
     * @param {number} activeSenderTokenId - Selected Token ID to send
     * @returns {Token} - Supported token for active network and selected token to send
     */
    public async supportedRecipientToken(networkId: number, activeSenderTokenId: number): Promise<Token> {
        const response = await this.http.get(
            `${this.ROOT_PATH}/networks/${networkId}/supported-tokens?token-id=${activeSenderTokenId}`
        );
        if (!response.ok) {
            await this.handleError(response);
        }

        const token: Token = (await response.json()) || [];

        return new Token(
            token.decimals,
            token.id,
            token.shortName,
            token.longName,
            token.smartContractAddress,
            token.iconLink,
        );
    };

    /** Requests supported tokens list.
     * @param {number} networkId - Active network ID to send transaction
     * @param {number} tokenId - Selected Token ID to send
     * @returns {Token} - Supported token for active network and selected token to send
     */
    public async supportedTokenById(networkId: number, tokenId: number): Promise<Token> {
        const response = await this.http.get(
            `${this.ROOT_PATH}/networks/${networkId}/supported-tokens?token-id=${tokenId}`
        );
        if (!response.ok) {
            await this.handleError(response);
        }

        const token: Token = (await response.json()) || [];

        return new Token(
            token.decimals,
            token.id,
            token.shortName,
            token.longName,
            token.smartContractAddress,
            token.iconLink,
        );
    };

    /** Requests token balance.
     * @param {number} networkId - Active network ID to send transaction
     * @param {number} tokenId - Active token ID to send transaction
     * @param {string} address - User wallet address to send transaction
     * @returns {string} - Current token balance
     */
    public async balance(networkId: number, tokenId: number, address: string): Promise<string> {
        const response = await this.http.get(
            `${this.ROOT_PATH}/networks/${networkId}/balance/${tokenId}/${address}`
        );
        if (!response.ok) {
            await this.handleError(response);
        }

        const balance = (await response.json()) || '';

        return balance;
    };
};
