/** Defines used local storage keys. */
export enum LocalStorageKeys {
    activeTokenId = 'TOKEN_ID',
    activeTokenContract = 'TOKEN_CONTRACT',
    metamaskSignature = 'METAMASK_SIGNATURE',
    senderNetworkId = 'SENDER_NETWORK_ID',
    recipientNetworkId = 'RECIPIENT_NETWORK_ID',
    casperSignature = 'CASPER_SIGNATURE',
    casperPublicKey = 'CASPER_PUBLIC_KEY',
    concordiumSignature = 'CONCORDIUM_SIGNATURE',
    concordiumAddress = 'CONCORDIUM_PUBLIC_KEY',
    metamaskAddress = 'METAMASK_ADDRESS',
    themeMode = 'THEME_MODE',
    isMetamaskConnected = 'IS_METAMASK_CONNECTED',
    isCasperConnected = 'IS_CASPER_CONNECTED',
    isConcordiumConnected = 'IS_CONCORDIUM_CONNECTED',
    isStellarConnected = 'IS_STELLAR_CONNECTED',
    stellarAddress = 'STELLAR_WALLET_ADDRESS',
    stellarSignature = 'STELLAR_SIGNATURE',
    isDisclaimerConfirmed = 'IS_DISCLAIMER_CONFIRMED',
    isXverseConnected = 'IS_XVERSE_CONNECTED',
    xverseAddress = 'XVERSE_ADDRESS',
    xversePaymentAddress = 'XVERSE_PAYMENT_ADDRESS',
    xversePaymentPubKey = 'XVERSE_PAYMENT_PUBKEY',
    xverseOrdinalsPubKey = 'XVERSE_ORDINALS_PUBKEY',
    xverseSignature = 'XVERSE_SIGNATURE',
}

/* Set value to localStorage */
export const setLocalStorageItem = (item: string, value: any, dispatchEventKey?: string) => {
    window.localStorage && window.localStorage.setItem(item, JSON.stringify(value));
    dispatchEventKey && window.dispatchEvent(new Event(dispatchEventKey));
};

/* Get value from localStorage */
export const getLocalStorageItem = (item: string) => {
    const storageItem: string | null = window.localStorage && window.localStorage.getItem(item);

    return storageItem ? JSON.parse(storageItem) : '';
};

/* Remove item from localStorage */
export const removeLocalStorageItem = (item: string) => window.localStorage && window.localStorage.removeItem(item);

/** Hook gets/sets/deletes local storage value. */
export const useLocalStorage = () => ({ setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem });
