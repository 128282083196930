import { walletsToShow } from '@app/constants/walletsToShow';
import { LocalStorageKeys, getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@app/hooks/useLocalStorage';
import { NotificationsPlugin as Notifications } from '@app/plugins/notifications';

/** Describes connected wallet name. */
export enum WalletName {
    METAMASK = 'Metamask',
    CASPER = 'Casper',
    CONCORDIUM = 'Concordium',
    FREIGHTER = 'Freighter',
    XVERSE = 'Xverse',
};

/** Describes connected wallet entity. */
export type Wallet = {
    name: WalletName;
    address: string;
    signature: string;
    paymentAddress?: string;
    paymentPubKey?: string;
    ordinalsPubKey?: string;
};

const validWalletNames = new Set(Object.values(WalletName));

for (const wallet of walletsToShow) {
  if (!validWalletNames.has(wallet)) {
    Notifications.notify(`Invalid wallet name: ${wallet}.`);
  }
}

/** Exposes connected wallets state. Uses as default state for reducer. */
const connectedWalletsStateInit: Wallet[] = [
    {
        name: WalletName.METAMASK,
        address: getLocalStorageItem(LocalStorageKeys.metamaskAddress),
        signature: getLocalStorageItem(LocalStorageKeys.metamaskSignature),
    },
    {
        name: WalletName.CASPER,
        address: getLocalStorageItem(LocalStorageKeys.casperPublicKey),
        signature: getLocalStorageItem(LocalStorageKeys.casperSignature),
    },
    {
        name: WalletName.CONCORDIUM,
        address: getLocalStorageItem(LocalStorageKeys.concordiumAddress),
        signature: getLocalStorageItem(LocalStorageKeys.concordiumSignature),
    },
    {
        name: WalletName.FREIGHTER,
        address: getLocalStorageItem(LocalStorageKeys.stellarAddress),
        signature: getLocalStorageItem(LocalStorageKeys.stellarSignature),
    },
    {
        name: WalletName.XVERSE,
        address: getLocalStorageItem(LocalStorageKeys.xverseAddress),
        signature: getLocalStorageItem(LocalStorageKeys.xverseSignature),
    },
].filter(wallet => walletsToShow.includes(wallet.name));

/** ConnectedWalletsReducerAction uses as action payload for reducer. */
class WalletsReducerAction {
    constructor(public type: string = '', public payload: any = '') { }
}

export const SET_CONNECTED_WALLETS: string = 'SET_CONNECTED_WALLETS';
export const DISCONNECT_WALLET: string = 'DISCONNECT_WALLET';
export const SET_WALLET_ACCOUNT: string = 'SET_WALLET_ACCOUNT';

type TWalletStorageMap = {
    [key in WalletName]: {
        address: LocalStorageKeys;
        signature: LocalStorageKeys;
    }
};
const walletStorageMap: TWalletStorageMap = {
    [WalletName.METAMASK]: {
        address: LocalStorageKeys.metamaskAddress,
        signature: LocalStorageKeys.metamaskSignature,
    },
    [WalletName.CASPER]: {
        address: LocalStorageKeys.casperPublicKey,
        signature: LocalStorageKeys.casperSignature,
    },
    [WalletName.CONCORDIUM]: {
        address: LocalStorageKeys.concordiumAddress,
        signature: LocalStorageKeys.concordiumSignature,
    },
    [WalletName.FREIGHTER]: {
        address: LocalStorageKeys.stellarAddress,
        signature: LocalStorageKeys.stellarSignature,
    },
    [WalletName.XVERSE]: {
        address: LocalStorageKeys.xverseAddress,
        signature: LocalStorageKeys.xverseSignature,
    },
};

/** WalletsReducer */
export const walletsReducer = (
    walletsState: Wallet[] = connectedWalletsStateInit,
    action: WalletsReducerAction = new WalletsReducerAction(),
) => {
    switch (action.type) {
        case SET_CONNECTED_WALLETS:
            walletsState = action.payload;
            break;
        case DISCONNECT_WALLET:
            walletsState = walletsState.map((wallet: Wallet) => {
                if (action.payload.walletName === wallet.name) {
                    wallet.address = '';
                    wallet.signature = '';
                    removeLocalStorageItem(walletStorageMap[wallet.name].address);
                    removeLocalStorageItem(walletStorageMap[wallet.name].signature);

                    if (wallet.name === WalletName.XVERSE) {
                        wallet.paymentAddress = '';
                        wallet.paymentPubKey = '';
                        wallet.ordinalsPubKey = '';
                        removeLocalStorageItem(LocalStorageKeys.xversePaymentAddress);
                        removeLocalStorageItem(LocalStorageKeys.xversePaymentPubKey);
                        removeLocalStorageItem(LocalStorageKeys.xverseOrdinalsPubKey);
                    }
                }

                return wallet;
            });
            break;
        case SET_WALLET_ACCOUNT:
            walletsState = walletsState.map((wallet: Wallet) => {
                if (action.payload.walletName === wallet.name) {
                    wallet.address = action.payload.address;
                    setLocalStorageItem(walletStorageMap[wallet.name].address, action.payload.address);
                    if(action.payload.signature) {
                        wallet.signature = action.payload.signature;
                        setLocalStorageItem(walletStorageMap[wallet.name].signature, action.payload.signature);
                    }
                }

                return wallet;
            });
            break;
        default:
            return walletsState;
    };

    return [...walletsState];
};
