import { createTheme } from '@mui/material';
import { mainFont, typographyVariants } from './typography';
import { palette } from './palette';
import bgTricornImg from '@static/images/bg.png';
import zIndex from '@mui/material/styles/zIndex';

const isBridgeTricorn = import.meta.env.VITE_IS_BRIDGE_TRICORN?.trim() !== 'false';
export const theme = createTheme({
    palette,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: isBridgeTricorn ? 'none' : palette.runesBackground,
                    backgroundImage: isBridgeTricorn ? `url(${bgTricornImg})` :'none',
                },
                '&.Mui-disabled': {
                    '-webkit-text-fill-color': 'rgba(155, 152, 146, 1) !important',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                fontFamily: isBridgeTricorn ? mainFont: '"Exo", sans-serif',
            },
        },
    },
    typography: {
        ...typographyVariants,
    },
});

export const gradientBorderStyle = {
    position: 'relative',
    borderColor: 'transparent',
    // before and after are duplicated to achieve smooth corners
    '&:before, &:after': {
        content: '""',
        position: 'absolute',
        zIndex: '-1',
        inset: 0,
        borderRadius: 'inherit',
        border: 'solid transparent',
        borderWidth: 'inherit',
        background: `${theme.palette.runesAccentGradient} border-box`,
        '-webkit-mask': 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
        mask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
        '-webkit-mask-composite': 'destination-out',
        'mask-composite': 'exclude',
    },
};
