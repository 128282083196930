import React from 'react';

type TFontWeight = 'regular' | 'medium' | 'semiBold' | 'bold';
type TTypographyHeadings = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type TTypographyText = 't1' | 't2' | 't3' | 't4' | 't4.5' | 't5' | 't6' | 't7';

const fontWeight: Record<TFontWeight, number> = {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
};

type TTypographyVariant = {
    [key in TFontWeight]: React.CSSProperties;
};

declare module '@mui/material/styles' {
    // https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
    type TTypographyVariants = {
        [key in `heading.${TTypographyHeadings}.${TFontWeight}`]: TTypographyVariant;
    } & {
        [key in `text.${TTypographyText}.${TFontWeight}`]: TTypographyVariant;
    };

    interface TypographyVariants extends TTypographyVariants { }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    type TTypographyOverrides = {
        [key in `heading.${TTypographyHeadings}.${TFontWeight}`]: true;
    } & {
        [key in `text.${TTypographyText}.${TFontWeight}`]: true;
    };

    interface TypographyPropsVariantOverrides extends TTypographyOverrides { }
}

export const mainFont = ['Inter', 'sans-serif'].join(',');

const getTypographyStyles = (baseFieldName: string, baseStyles: Record<string, unknown>) => {
    const keys = Object.keys(fontWeight) as TFontWeight[];

    return keys.reduce((acc, weight) => ({
        ...acc,
        [`${baseFieldName}.${weight}`]: { fontWeight: fontWeight[weight], ...baseStyles },
    }), {});
};

export const typographyVariants = {
    // heading
    ...getTypographyStyles('heading.h6', {
        fontSize: '24px',
        lineHeight: '30px',
    }),
    // text
    ...getTypographyStyles('text.t2', {
        fontSize: '20px',
        lineHeight: '26px',
    }),
    ...getTypographyStyles('text.t3', {
        fontSize: '18px',
        lineHeight: '24px',
    }),
    ...getTypographyStyles('text.t4', {
        fontSize: '16px',
        lineHeight: '20px',
    }),
    ...getTypographyStyles('text.t4.5', {
        fontSize: '14px',
        lineHeight: '17px',
    }),
    ...getTypographyStyles('text.t5', {
        fontSize: '12px',
        lineHeight: '14px',
    }),
    ...getTypographyStyles('text.t6', {
        fontSize: '10px',
        lineHeight: '12px',
    }),
    ...getTypographyStyles('text.t7', {
        fontSize: '8px',
        lineHeight: '10px',
    }),
};
