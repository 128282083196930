import { Dispatch } from 'redux';
import appConfig from '@/app/configs/appConfig.json';
import { NotificationsPlugin as Notifications } from '@/app/plugins/notifications';

import { NetworksClient } from '@/api/networks';
import { Network, Token, TokenResponse, TokenSearch } from '@/networks';
import { NetworksService } from '@/networks/service';

export const SET_CONNECTED_NETWORKS: string = 'SET_CONNECTED_NETWORKS';
export const SET_SUPPORTED_TOKENS: string = 'SET_SUPPORTED_TOKENS';
export const SET_ACTIVE_SUPPORTED_TOKEN: string = 'SET_ACTIVE_SUPPORTED_TOKEN';
export const SET_TOKEN_BALANCE: string = 'SET_TOKEN_BALANCE';
export const CLEAR_NETWORK_DATA: string = 'CLEAR_NETWORK_DATA';
export const SET_ACTIVE_SUPPORTED_TOKEN_WITHOUT_RECIPIENT: string = 'SET_ACTIVE_SUPPORTED_TOKEN_WITHOUT_RECIPIENT';
export const SET_ACTIVE_SUPPORTED_RECIPIENT_TOKEN: string ='SET_ACTIVE_SUPPORTED_RECIPIENT_TOKEN';

/** An action setConnectedNetworks contains type and payload data for sets connected networks. */
export const setConnectedNetworks = (networks: Network[]) => ({
    type: SET_CONNECTED_NETWORKS,
    payload: networks,
});

/** An action setSupportedTokens contains type and payload data for sets supported tokens. */
export const setSupportedTokens = (tokens: TokenResponse) => ({
    type: SET_SUPPORTED_TOKENS,
    payload: tokens,
});

/** An action setActiveSupportedToken contains type and payload data for sets supported token. */
export const setActiveSupportedToken = (token: Token, recipientNetworks: Network[]) => ({
    type: SET_ACTIVE_SUPPORTED_TOKEN,
    payload: { token, recipientNetworks },
});

/** An action setActiveSupportedToken contains type and payload data for sets supported token. */
export const setActiveSupportedRecipientToken = (token: Token) => ({
    type: SET_ACTIVE_SUPPORTED_RECIPIENT_TOKEN,
    payload: token,
});


/** An action setActiveSupportedToken contains type and payload data for sets supported token. */
export const setActiveSupportedTokenWithoutRecipient = (token: Token) => ({
    type: SET_ACTIVE_SUPPORTED_TOKEN_WITHOUT_RECIPIENT,
    payload: token,
});

/** An action setBalance contains type and payload data for sets token balance. */
export const setBalance = (balance: string) => ({
    type: SET_TOKEN_BALANCE,
    payload: balance,
});

/** An action clearNetworkData contains type for cleaning network data. */
export const clearNetworkData = () => ({
    type: CLEAR_NETWORK_DATA,
});

const networksClient = new NetworksClient();
const networksService = new NetworksService(networksClient);

/** Thunk middleware that requests connected networks list and sets into reducer. */
export const getConnectedNetworks = () => async function (dispatch: Dispatch) {
    const connectedNetworks = await networksService.connected();
    dispatch(setConnectedNetworks(connectedNetworks));
};

/** Thunk middleware that requests supported tokens list and sets into reducer. */
export const getSupportedTokens = (networkId: number, filter?: TokenSearch) => async function (dispatch: Dispatch) {
    const supportedTokens = await networksService.supportedTokens(networkId, filter);
    dispatch(setSupportedTokens(supportedTokens));
};

export const getActiveSupportedTokenById = async (networkId: number, tokenId: number): Promise<Token> => {
    const supportedToken = await networksService.supportedTokenById(networkId, tokenId);

    return supportedToken;
};

export const requestUpdateUserTokens = async (networkId: number, userAddress: string): Promise<void> => {
    await networksService.userTokens(networkId, userAddress);
};

/** Thunk middleware that requests supported recipient tokens list and sets into reducer. */
export const getSupportedRecipientToken = (networkId: number, activeSupportedToken: Token) => async function (dispatch: Dispatch) {
    const supportedToken = await networksService.supportedRecipientToken(networkId, activeSupportedToken.id);
    dispatch(setActiveSupportedRecipientToken(supportedToken));
};

/** Function for make balance shortly */
const truncateBalance = (balance: string) => {
    const decimalIndex = balance.indexOf('.');
    if (decimalIndex !== appConfig.numbers.MINUS_ONE_NUMBER) {
        const integerPart = balance.substring(appConfig.numbers.ZERO_NUMBER, decimalIndex);
        const decimalPart = balance.substring(decimalIndex + appConfig.numbers.ONE_NUMBER);
        // const truncatedDecimalPart = decimalPart.substring(appConfig.numbers.ZERO_NUMBER, appConfig.numbers.SIX_NUMBER);

        return `${integerPart}.${decimalPart}`;
    }

    return balance;
};

/** Thunk middleware that requests token balance and sets into reducer. */
export const getBalance = (networkId: number, tokenId: number, address: string) => async function (dispatch: Dispatch) {
    try {
        const balance = await networksService.balance(networkId, tokenId, address);
        const finalBalance = truncateBalance(balance);
        dispatch(setBalance(finalBalance.toString()));
    } catch {
        Notifications.couldNotGetBalance();
    }
};

/** Thunk middleware that requests connected networks list and sets into reducer. */
export const changeActiveSupportedToken = (token: Token, tokenType: string) => async function (dispatch: Dispatch) {
    let recipientNetworks: Network[] = [];

    if (token.id) {
        recipientNetworks = await networksService.connected(token.id);
    }

    if (tokenType === 'recipient') {
        dispatch(setActiveSupportedRecipientToken(token));

        return;
    }

    dispatch(setActiveSupportedToken(token, recipientNetworks));
};

